import { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '@/components/Button';
import { KargoBanner } from '@/components/KargoBanner/KargoBanner';
import Layout from '@/components/Layout';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import AkuityDiagramMobile from '@/static/assets/icons/AkuityPlatform/new/akuity-diagram-mobile.svg';
import AkuityDiagram from '@/static/assets/icons/AkuityPlatform/new/akuity-diagram.svg';
import AppCountIcon from '@/static/assets/icons/AkuityPlatform/new/icn-app-count.svg';
import ArrowUpIcon from '@/static/assets/icons/AkuityPlatform/new/icn-arrow-up.svg';
import BellIcon from '@/static/assets/icons/AkuityPlatform/new/icn-bell.svg';
import CliIcon from '@/static/assets/icons/AkuityPlatform/new/icn-cli.svg';
import ClusterIcon from '@/static/assets/icons/AkuityPlatform/new/icn-cluster.svg';
import EyeControlIcon from '@/static/assets/icons/AkuityPlatform/new/icn-eye-control.svg';
import FanOutIcon from '@/static/assets/icons/AkuityPlatform/new/icn-fan-out.svg';
import GlobeIcon from '@/static/assets/icons/AkuityPlatform/new/icn-globe.svg';
import GraphGrowIcon from '@/static/assets/icons/AkuityPlatform/new/icn-graph-grow.svg';
import LockIcon from '@/static/assets/icons/AkuityPlatform/new/icn-lock.svg';
import PuzzleIcon from '@/static/assets/icons/AkuityPlatform/new/icn-puzzle.svg';
import SafeIcon from '@/static/assets/icons/AkuityPlatform/new/icn-safe.svg';
import ScaleDownIcon from '@/static/assets/icons/AkuityPlatform/new/icn-scale-down.svg';
import ScaleIcon from '@/static/assets/icons/AkuityPlatform/new/icn-scale.svg';
import ServersIcon from '@/static/assets/icons/AkuityPlatform/new/icn-servers.svg';
import SheetIcon from '@/static/assets/icons/AkuityPlatform/new/icn-sheet.svg';
import SwitchIcon from '@/static/assets/icons/AkuityPlatform/new/icn-switch.svg';
import TwistArrowUpIcon from '@/static/assets/icons/AkuityPlatform/new/icn-twist-arrow-up.svg';
import AksIcon from '@/static/assets/icons/AkuityPlatform/new/provider-aks.svg';
import CrossplaneIcon from '@/static/assets/icons/AkuityPlatform/new/provider-crossplane.svg';
import EksIcon from '@/static/assets/icons/AkuityPlatform/new/provider-eks.svg';
import GkeIcon from '@/static/assets/icons/AkuityPlatform/new/provider-gke.svg';
import OpenShiftIcon from '@/static/assets/icons/AkuityPlatform/new/provider-openshift.svg';
import TerraformIcon from '@/static/assets/icons/AkuityPlatform/new/provider-terraform.svg';
import SmileyIcon from '@/static/assets/icons/HomePage/icn-smiley.svg';
import { seoConfiguration } from '@/utils/seo-config';
import './akuity-platform.scss';

const AkuityCloudPage = () => {
  const { title: titleSeo, image, description } = seoConfiguration.akuityCloud;
  const [img, setImg] = useState(1);

  const { isBreakpointRange } = useBreakpoints();

  const isTablet = isBreakpointRange('tablet');

  return (
    <>
      <Layout image={image} title={titleSeo} description={description}>
        <div className="akuity-platform">
          <div className="header">
            <h1>Akuity Platform</h1>
            <h3>
              Simpler and safer Kubernetes application delivery, using Argo CD
            </h3>

            <Button
              additionalClass="cta secondary-grey button-hover-color--secondary"
              color="akuity-purple"
              link="/signup"
            >
              Start a 30-day free trial
            </Button>
          </div>

          <div className="highlight-wrapper">
            <h2>Same Argo CD, but better</h2>
            <h3>
              Fast setup, better security, less operational headaches, and much
              more
            </h3>

            <div className="features">
              <div className="features__item">
                <div className="features__icon">
                  <SafeIcon />
                </div>
                <h5>Next-level Security</h5>
                <ul>
                  <li>
                    Secrets are isolated within the cluster, limiting
                    vulnerability to 3rd party intrusions
                  </li>
                  <li>
                    No need to have Kubernetes credentials in a centralized
                    location
                  </li>
                  <li>
                    Networks remain private, with only outbound traffic required
                  </li>
                </ul>
              </div>
              <div className="features__item">
                <div className="features__icon">
                  <EyeControlIcon />
                </div>
                <h5>Single Control Plane</h5>
                <ul>
                  <li>
                    Manage all your applications and environments using one
                    control plane
                  </li>
                  <li>
                    One click version updates across all apps and environments
                  </li>
                </ul>
              </div>
              <div className="features__item">
                <div className="features__icon">
                  <ScaleIcon />
                </div>
                <h5>Scale up Instantly</h5>
                <ul>
                  <li>No need for manual sharding</li>
                  <li>No need for fine-tuning Argo CD components</li>
                  <li>Better resource allocation</li>
                  <li>
                    No network bottlenecks, we only transmit deployment metadata
                  </li>
                </ul>
              </div>
            </div>
            <Button
              additionalClass="cta secondary-grey button-hover-color--secondary"
              color="akuity-purple"
              link="/get-demo"
            >
              Get a Technical Demo
            </Button>
          </div>

          <h2>No operational headaches</h2>
          <h3>
            Take the complexity out of managing multiple Argos, and streamline
            the deployment pipeline through simple and easy-to-use interface
          </h3>

          <div className="product">
            <div className="product__nav">
              <button
                onClick={() => setImg(1)}
                className={img === 1 ? 'active' : ''}
              >
                Every Argo CD instance in one place
              </button>
              <button
                onClick={() => setImg(2)}
                className={img === 2 ? 'active' : ''}
              >
                Simplified cluster management
              </button>
              <button
                onClick={() => setImg(3)}
                className={img === 3 ? 'active' : ''}
              >
                Easy configuration of Argo CD
              </button>
            </div>
            <div className="product__images">
              <div
                className={`product__image${
                  img === 1 ? ' product__image--visible' : ''
                }`}
              >
                <StaticImage
                  src="../../static/assets/images/AkuityPlatform/new/product-screenshot-instances.png"
                  alt="Akuity - Instances"
                  placeholder="blurred"
                />
                <div>
                  <div className="chip">
                    <SmileyIcon />
                    Declarative management of instances
                  </div>
                  <div className="chip">
                    <SmileyIcon />
                    No management cluster required
                  </div>
                </div>
              </div>
              <div
                className={`product__image${
                  img === 2 ? ' product__image--visible' : ''
                }`}
              >
                <StaticImage
                  src="../../static/assets/images/AkuityPlatform/new/product-screenshot-clusters.png"
                  alt="Akuity - Clusters"
                  placeholder="blurred"
                />
                <div>
                  <div className="chip">
                    <SmileyIcon />
                    Cluster dashboard with health status
                  </div>
                  <div className="chip">
                    <SmileyIcon />
                    Automatic cluster agent updates
                  </div>
                  <div className="chip">
                    <SmileyIcon />
                    One-click credentials rotation
                  </div>
                </div>
              </div>
              <div
                className={`product__image${
                  img === 3 ? ' product__image--visible' : ''
                }`}
              >
                <StaticImage
                  src="../../static/assets/images/AkuityPlatform/new/product-screenshot-dashboard.png"
                  alt="Akuity - Dashboard"
                  placeholder="blurred"
                />
                <div>
                  <div className="chip">
                    <SmileyIcon />
                    Overview of application health and sync status
                  </div>
                  <div className="chip">
                    <SmileyIcon />
                    CVE detection and security dashboard
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2>Innovative Agent-based Architecture</h2>

          <div className="diagram-img">
            {isTablet ? <AkuityDiagramMobile /> : <AkuityDiagram />}
          </div>

          <div className="diagram-features">
            <div className="chip">
              <SmileyIcon />
              Less traffic
            </div>
            <div className="chip">
              <SmileyIcon />
              Single control plane
            </div>
            <br />
            <div className="chip">
              <SmileyIcon />
              Low maintenance
            </div>
            <div className="chip">
              <SmileyIcon />
              Superior security
            </div>
            <div className="chip">
              <SmileyIcon />
              Extra features
            </div>
          </div>

          <div className="features">
            <div className="features__item">
              <h5>Superior Security</h5>
              <p>
                The Akuity Platform has an agent-based architecture, allowing
                the cluster to connect to the Argo control plane (not the other
                way around). Secrets stay safe in the Kubernetes clusters.
              </p>
            </div>
            <div className="features__item">
              <h5>Strong Compliance and Governance</h5>
              <p>
                The platform provides an extra layer of visibility with audit
                logs, deployment reports, and DORA metrics. It also takes care
                of governance with SSO configuration, RBAC, system accounts, and
                external access configuration.
              </p>
            </div>
            <div className="features__item">
              <h5>Single Control Plane</h5>
              <p>
                Akuity provides one control plane for all Argo CD applications
                deployed to clusters.
              </p>
            </div>
          </div>

          <h2>Akuity Platform Features</h2>
          <h3>
            Extend the power of Argo, making Kubernetes deployments easier and
            controllable.
          </h3>

          <h4 className="line">
            <span>Security</span>
          </h4>

          <div className="features">
            <div className="features__item">
              <div className="features__icon">
                <LockIcon />
              </div>
              <h5>Hybrid Agent-based Architecture</h5>
              <p>
                Akuity doesn’t need secrets nor even direct access to your
                Kubernetes clusters. Thanks to an agent-based architecture, the
                clusters connect to the Argo control plane and not the other way
                around.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <SheetIcon />
              </div>
              <h5>Built-in Auditing</h5>
              <p>
                Log, store, and analyze all the important events that took place
                inside your Argo CD control plane. Increase discoverability of
                any security liabilities and react instantly.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <GlobeIcon />
              </div>
              <h5>Disaster Recovery and High Availability</h5>
              <p>
                Control plane is configured with high availability
                out-of-the-box and configurations are backed up frequently. No
                need to recreate your Argo CD configuration after your cluster
                goes down.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <ArrowUpIcon />
              </div>
              <h5>Argo CD Upgrades and Security Patches</h5>
              <p>
                Decide which Argo CD version you want to run and Akuity will
                ensure that your clusters will be seamlessly updated or
                downgraded to it. This also includes applying security patches
                right after their release.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <ClusterIcon />
              </div>
              <h5>Flexible Argo CD Architecture</h5>
              <p>
                Dynamically choose a host cluster for Argo CD components. The
                following components&apos; location is configurable: Application
                Set, Repo Server or Image Updater.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <ServersIcon />
              </div>
              <h5>On-premises Solution Available</h5>
              <p>
                The on-premises version of Akuity is a fully-functional branch
                of the product that will be updated with new features and
                innovations alongside the SaaS offering.
              </p>
            </div>
          </div>

          <h4 className="line">
            <span>Scalability & Cost Optimization</span>
          </h4>

          <div className="features">
            <div className="features__item">
              <div className="features__icon">
                <AppCountIcon />
              </div>
              <h5>Massive Scalability</h5>
              <p>
                With custom fine tuning and unique distributed architecture,
                Akuity can support 35K applications inside one Argo CD instance.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <ScaleDownIcon />
              </div>
              <h5>Traffic reduction</h5>
              <p>
                Akuity reduces traffic between the Argo control plane and your
                Kubernetes clusters by at least 80%. This enables you to manage
                all the edge clusters without worrying about the bandwidth
                limit, and save money.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <TwistArrowUpIcon />
              </div>
              <h5>Built-in Autoscaler</h5>
              <p>
                With a built-in and configured autoscaler, your Argo CD will be
                automatically tuned to accommodate the ever changing size of
                managed infrastructure with the guarantee of high performance.
              </p>
            </div>
          </div>

          <div className="compatibility">
            <div className="compatibility__title">
              Compatible with GKE, EKS, AKS, OpenShift, Crossplane, and
              Terraform.
            </div>
            <div className="compatibility__list">
              <div className="compatibility__item">
                <GkeIcon />
                Google Kubernetes Engine
              </div>
              <div className="compatibility__item">
                <EksIcon />
                Amazon Elastic Kubernetes Service
              </div>
              <div className="compatibility__item">
                <AksIcon />
                Azure Kubernetes Service
              </div>
              <div className="compatibility__item">
                <OpenShiftIcon />
                OpenShift
              </div>
              <div className="compatibility__item">
                <CrossplaneIcon />
                Crossplane
              </div>
              <div className="compatibility__item">
                <TerraformIcon />
                Terraform
              </div>
            </div>
          </div>

          <h4 className="line">
            <span>Developer Experience</span>
          </h4>

          <div className="features">
            <div className="features__item">
              <div className="features__icon">
                <FanOutIcon />
              </div>
              <h5>Change Promotion Orchestration</h5>
              <p>
                Model the delivery of application changes in a more granular,
                environment-specific way.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <SwitchIcon />
              </div>
              <h5>Basic Settings Management</h5>
              <p>
                Manage the basic Argo CD settings from Akuity dashboard in a
                declarative way – customize your Argo CD in a flash.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <BellIcon />
              </div>
              <h5>Argo CD Notifications</h5>
              <p>
                Configure your Argo CD notifications using Akuity’s intuitive
                user interface.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <GraphGrowIcon />
              </div>
              <h5>Deployment Metrics and History</h5>
              <p>
                Store and analyze deployment metrics inside 
                the Akuity Platform. Troubleshoot failed 
                deployments faster.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <PuzzleIcon />
              </div>
              <h5>Argo CD Extensions Catalog</h5>
              <p>
                Enable such crucial Argo CD extensions as Argo Rollouts UI and
                Argo Metrics with one click.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <CliIcon />
              </div>
              <h5>More than UI – Akuity API/CLI</h5>
              <p>
                Explore more ways of automating and integrating Akuity with your
                tools and apps by using Akuity API and CLI.
              </p>
            </div>
          </div>

          <Button
            additionalClass="cta secondary-grey button-hover-color--secondary"
            color="akuity-purple"
            link="/signup"
          >
            Start a 30-day free trial
          </Button>
          <KargoBanner />
        </div>
      </Layout>
    </>
  );
};

export default AkuityCloudPage;
